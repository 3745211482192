<template>
    <div>
        <nav-bar title="发票申请" url="-1"></nav-bar>

        <div class="top">
            <div class="title">
                <div class="title-text">用户信息</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-form @submit="submit">
                <van-cell-group>
                    <van-field label-width="70" label-class="label" v-model="data.name" label="单位名称"
                        placeholder="请输入单位名称" :rules="[{ required: true, message: '' }]"></van-field>

                    <van-field label-width="70" label-class="label" v-model="data.code" label="税号" placeholder="请输入税号"
                        :rules="[{ required: true, message: '' }]"></van-field>

                    <van-field label-width="70" label-class="label" v-model="data.address" label="单位地址"
                        placeholder="请输入单位地址"></van-field>

                    <van-field label-width="70" label-class="label" v-model="data.tel" label="电话号码"
                        placeholder="请输入电话号码"></van-field>

                    <van-field label-width="70" label-class="label" v-model="data.bank" label="开户银行"
                        placeholder="请输入开户银行"></van-field>

                    <van-field label-width="70" label-class="label" v-model="data.no" label="银行账户"
                        placeholder="请输入银行账户"></van-field>

                    <van-field label-width="70" label-class="label" v-model="data.email" label="接收邮箱"
                        placeholder="请输入接收邮箱" :rules="[{ required: true, message: '' }]"></van-field>
                </van-cell-group>

                <div class="action">
                    <van-button v-if="info.company_invoice_state < 2" round type="primary"
                        :disabled="info.company_invoice_state>0?true:false" native-type="submit"
                        :color="config.main_color" size="large">
                        <span v-if="info.company_invoice_state==0">提交申请</span>
                        <span v-if="info.company_invoice_state==1">开票中</span>
                        <span v-if="info.company_invoice_state==2">开票成功</span>
                    </van-button>

                    <van-button class="download" v-if="info.company_invoice_state==2" round type="primary" color="red"
                        size="large" @click="url(info.company_invoice_file)">
                        <span>发票下载</span>
                    </van-button>
                </div>
            </van-form>
        </div>

    </div>
</template>

<script>
    import { company_invoice_submit, company_info } from '@/api/company.js';
    import {
        Toast,
        Notify,
        Dialog,
    } from 'vant';
    export default {
        name: 'apply',
        data() {
            return {
                info: {},
                data: {

                },
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {
            get_data() {
                company_info({ id: this.$route.query.id }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                        if(res.data.company_invoice_content){
                            this.data = res.data.company_invoice_content;
                        }
                    }
                });
            },



            //提交
            submit() {
                var that = this;
                if (!that.data.name) {
                    Toast('单位名称不得为空');
                    return false;
                }

                if (!that.data.code) {
                    Toast('税号不得为空');
                    return false;
                }

                company_invoice_submit({
                    id: that.$route.query.id,
                    invoice: that.data,
                    loading: true,
                    loading_text: '提交中……'
                }).then(res => {
                    if (res.code == 1) {
                        Dialog.alert({
                            message: res.msg,
                        }).then(res2 => {
                            that.$router.back();
                        });
                    }
                });
            },


            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },

        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    .van-button--primary {
        background-image: url('../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }

    /deep/ .label {
        font-family: 'FZCS';
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    .action {
        margin: 20px;
        width: calc(100% - 40px);
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    .download {
        margin-top: 15px;
    }
</style>